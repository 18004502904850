<template>
  <el-dialog
    top="80px"
    width="500px"
    class="create-children"
    :title="`Tạo mới ${label.toLowerCase()} con`"
    :visible="visible"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form v-if="parent" ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item :label="`${label.toLowerCase()} cha`" prop="area">
        <el-select v-model="parent.id" disabled filterable>
          <el-option v-for="p in [parent]" :key="p.id" :label="p.name" :value="p.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Tên" prop="name">
        <el-input v-model="form.name" :disabled="callingAPI" placeholder="Nhập vào tên để tạo mới"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="$emit('close')">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleCreateChildren">Tạo</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createPlace } from '@/services/place'
export default {
  name: 'CreateChildren',
  props: {
    visible: Boolean,
    type: String,
    label: String,
    parent: Object,
    areaId: String
  },
  data() {
    return {
      callingAPI: false,
      form: {
        name: ''
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Tên không được để trống.',
            trigger: ['blur, change']
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    itemInput() {
      return {
        name: this.form.name,
        locale: this.language,
        parent_id: this.parent.id,
        place_type: this.type,
        area_id: this.areaId || null
      }
    }
  },
  methods: {
    handleCreateChildren() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          createPlace(this.itemInput).then(() => {
            this.callingAPI = false
            this.$emit('close')
            this.$emit('reload')
            this.$notify.success({
              title: 'Thông báo',
              message: 'Tạo mới thành công'
            })
          }).catch((error) => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.create-children {
  .el-select {
    width: 100%;
  }
  .el-form-item__label {
    text-transform: capitalize;
  }
}
</style>
