import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getCampaigns(params) {
  return request({
    url: ENDPOINT.LIST_CAMPAIGNS,
    method: 'get',
    params
  })
}

export function getCampaignById(params) {
  return request({
    url: ENDPOINT.CAMPAIGN,
    method: 'get',
    params
  })
}

export function createCampaign(data) {
  return request({
    url: ENDPOINT.CAMPAIGN,
    method: 'post',
    data
  })
}

export function updateCampaign(data) {
  return request({
    url: ENDPOINT.CAMPAIGN,
    method: 'patch',
    data
  })
}

export function deleteCampaign(params) {
  return request({
    url: ENDPOINT.CAMPAIGN,
    method: 'delete',
    params
  })
}
