<template>
  <el-dialog
    top="80px"
    width="500px"
    class="create-item"
    :title="'Tạo mới'"
    :visible="visible"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="Tên" prop="name">
        <el-input v-model="form.name" :disabled="callingAPI" placeholder="Nhập vào tên để tạo mới"></el-input>
      </el-form-item>
      <el-form-item v-if="!['area', 'country'].includes(type)" label="Danh mục" prop="category">
        <el-select v-model="form.category" :disabled="callingAPI" filterable>
          <el-option v-for="c in categories" :key="c.id" :label="c.name" :value="c.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="['tour', 'post'].includes(type)" label="Loại điểm đến" prop="placeType">
        <el-select
          v-model="form.placeType"
          filterable
          class="w-100"
          @change="handleChangePlaceType"
        >
          <el-option v-for="t in placeTypes" :key="t.value" :label="t.name" :value="t.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="['tour', 'post'].includes(type)" label="Điểm đến" prop="place">
        <el-select
          v-model="form.place"
          class="w-100"
          filterable
          remote
          placeholder="Chọn điểm đến"
          :remote-method="searchPlace"
          :loading="searching"
        >
          <el-option v-for="p in places" :key="p.id" :label="p.name" :value="p.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="['event'].includes(type)" label="Thời gian diễn ra" prop="times">
        <el-date-picker
          v-model="form.times"
          type="daterange"
          range-separator="-"
          start-placeholder="Ngày bắt đầu"
          end-placeholder="Ngày kết thúc"
          format="dd-MM-yyyy"
          value-format="yyyy-MM-ddT00:00:00"
          class="w-100"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="$emit('close')">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleCreateItem">Tạo</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createTour } from '@/services/tour'
import { createPlace, listPlaces } from '@/services/place'
import { createPost } from '@/services/post'
import { getPlaceTypes } from '@/utils/filters'

export default {
  name: 'CreateItem',
  props: {
    visible: Boolean,
    type: String,
    areaId: String
  },
  data() {
    return {
      callingAPI: false,
      searchText: '',
      searching: false,
      places: [],
      form: {
        name: '',
        category: '',
        placeType: '',
        place: '',
        times: ''
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Tên không được để trống.',
            trigger: ['blur, change']
          }
        ],
        category: [
          {
            required: true,
            message: 'Danh mục được để trống.',
            trigger: ['blur, change']
          }
        ],
        placeType: [
          {
            required: true,
            message: 'Loại điểm đến không được để trống.',
            trigger: ['blur, change']
          }
        ],
        place: [
          {
            required: true,
            message: 'Điểm đến không được để trống.',
            trigger: ['blur, change']
          }
        ],
        times: [
          {
            required: true,
            message: 'Thời gian diễn ra không được để trống.',
            trigger: ['blur, change']
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    placeTypes() {
      return getPlaceTypes().filter((p) => p.type === 'place')
    },
    params() {
      return {
        locale: this.language,
        place_types: this.form.placeType,
        per_page: 10,
        search_text: this.searchText
      }
    },
    areas() {
      return this.constant.constants.areas
    },
    categories() {
      if (this.type === 'tour') {
        return this.constant.constants.tour_categories
      }
      if (this.type === 'post') {
        return this.constant.constants.post_categories
      }
      return this.constant.constants.place_categories.filter(
        (c) => c.place_type === this.type
      )
    },
    itemInput() {
      const data = {
        name: this.form.name,
        locale: this.language,
        category_id: this.form.category
      }
      if (['tour', 'post'].includes(this.type)) {
        return {
          ...data,
          place_id: this.form.place,
          place_type: this.form.placeType,
          area_id: this.areaId || null
        }
      }
      if (['area', 'country'].includes(this.type)) {
        return {
          name: this.form.name,
          locale: this.language,
          place_type: this.type,
          area_id: this.areaId || null
        }
      }
      if (['event'].includes(this.type)) {
        return {
          ...data,
          area_id: this.areaId || null,
          place_type: this.type,
          ended_at: this.form.times[1],
          started_at: this.form.times[0]
        }
      }
      return {
        ...data,
        place_type: this.type,
        area_id: this.areaId || null
      }
    }
  },
  beforeMount() {
    this.form = {
      name: '',
      category: this.categories[0] ? this.categories[0].id : '',
      area: this.areas[0] ? this.areas[0].id : ''
    }
  },
  methods: {
    getPlaces() {
      this.searching = true
      const request = listPlaces(this.params)
      request.then((res) => {
        this.places = res.result
        this.searching = false
        if (!this.form.place) {
          this.form.place = this.places[0].id
        }
      }).catch(() => {
        this.searching = false
      })
    },
    handleChangePlaceType() {
      this.form.place = ''
      this.searchText = ''
      this.getPlaces()
    },

    searchPlace(text) {
      this.searchText = text
      setTimeout(() => {
        this.getPlaces()
      }, 100)
    },

    handleCreateItem() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          let request = null
          if (this.type === 'tour') {
            request = createTour(this.itemInput)
          } else if (this.type === 'post') {
            request = createPost(this.itemInput)
          } else {
            request = createPlace(this.itemInput)
          }
          request.then(() => {
            this.callingAPI = false
            this.$emit('close')
            this.$emit('reload')
            this.$notify.success({
              title: 'Thông báo',
              message: 'Tạo mới thành công'
            })
            if (['country', 'area'].includes(this.type)) {
              this.$emit('reload-constant')
            }
          }).catch((error) => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.create-item {
  .el-select {
    width: 100%;
  }
}
</style>
