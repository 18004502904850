<template>
  <div class="shared-index">
    <el-row
      class="table-title"
      type="flex"
      align="center"
      justify="space-between"
    >
      <div class="table-title__left">
        <el-tabs v-model="activeTab" type="card">
          <el-tab-pane
            v-for="title in titles"
            :key="title.index"
            :label="pageTitle(title.value)"
            :name="title.value"
          ></el-tab-pane>
        </el-tabs>
        <div
          v-for="button in tableHeaderActions"
          :key="button.key"
          class="mr-2"
        >
          <synchronize-button
            v-if="button.key === 'synchronize'"
            size="small"
            :disabled="loading"
            :data="tableData"
            :type="type"
          />
          <export-excel-button
            v-else-if="button.key === 'export'"
            size="small"
            :disabled="loading"
            :order="order"
            :total="total"
            :columns="tableColumns"
            :parent-id="parentId"
            :type="type"
            :area-id="areaId"
            :titles="titles"
            :active-tab="activeTab"
          />
          <el-button
            v-else
            size="small"
            :type="button.type"
            :icon="button.icon"
            :disabled="loading"
            @click="handleHeaderAction(button)"
          >
            {{ button.label }}
          </el-button>
        </div>
      </div>
      <div v-if="filterable" class="table-title__right">
        <el-input
          v-model="searchText"
          suffix-icon="el-icon-search"
          placeholder="Tìm kiếm theo tên"
          size="small"
          :disabled="loading"
          @keyup.enter.native="handleSearchText"
        ></el-input>
      </div>
    </el-row>
    <div class="table">
      <el-table
        v-loading="loading"
        style="width: 100%"
        :data="tableData"
        :sort-orders="['ascending', 'descending', '']"
        @sort-change="handleSortData"
      >
        <el-table-column
          v-for="column in tableColumns"
          :key="column.key"
          :label="column.label"
          :width="column.width"
          :align="column.align"
          :sortable="column.sortable"
          :prop="column.key"
        >
          <template slot-scope="scope">
            <table-column
              v-if="column.key !== 'actions'"
              :type="type"
              :column="column"
              :row="scope.row"
              :language="language"
            />
            <el-row v-else type="flex" align="middle" justify="center">
              <el-tooltip
                v-for="action in tableActions"
                :key="action.key"
                :content="action.label"
                placement="top"
              >
                <router-link v-if="action.key === 'edit'" :to="getToShowRouter(scope.row)">
                  <el-button
                    circle
                    class="mx-2"
                    size="small"
                    :icon="action.icon"
                    :type="action.type"
                    @click="handleAction(action.key, scope.row)"
                  ></el-button>
                </router-link>
                <el-button
                  v-else
                  circle
                  size="small"
                  :icon="action.icon"
                  :type="action.type"
                  @click="handleAction(action.key, scope.row)"
                ></el-button>
              </el-tooltip>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-row type="flex" align="middle" justify="end" class="pagination">
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 20, 30]"
        :page-size.sync="limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleChangeSize"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <qrcode-popup
      :visible="qrVisible"
      :row="selectedRow"
      @close="qrVisible = false"
    />
    <create-item-popup
      v-if="createVisible"
      :visible="createVisible"
      :type="type"
      :area-id="areaId"
      @close="createVisible = false"
      @reload="handleReloadDada"
      @reload-constant="handleReloadConstant"
    />
    <create-campaign-popup
      v-if="createCampainVisible"
      :visible="createCampainVisible"
      :type="activeTab"
      :area-id="areaId"
      @close="createCampainVisible = false"
      @reload="handleReloadDada"
    />
    <create-children-popup
      v-if="childrendVisible"
      :visible="childrendVisible"
      :type="type"
      :parent="selectedRow"
      :label="titles.find((t) => t.value === activeTab).label"
      :area-id="areaId"
      @close="childrendVisible = false"
      @reload="$emit('reload')"
    />
    <filter-drawer
      ref="shared-filter"
      :visible="visibleFilter"
      :type="type"
      :area-id="areaId"
      @close="visibleFilter = false"
      @filter="handleFilterData"
    ></filter-drawer>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters, mapActions } from 'vuex'

import CONSTANTS from '@/config/constants'
import { listTours, deleteTour } from '@/services/tour'
import { listPlaces, deletePlace } from '@/services/place'
import { listPosts, deletePost } from '@/services/post'
import { getComments, deleteComment, updateComment } from '@/services/comment'
import { getCampaigns, deleteCampaign } from '@/services/campaign'

import TableColumn from './components/TableColumn'
import QrcodePopup from '../popups/Qrcode'
import CreateItemPopup from '../popups/CreateItem'
import CreateCampaignPopup from '../popups/CreateCampaign.vue'
import CreateChildrenPopup from '../popups/CreateChildren'
import FilterDrawer from './components/Filter'
import SynchronizeButton from '../buttons/Synchronize'
import ExportExcelButton from '../buttons/ExportExcel.vue'

import { getToShowRouter } from '@/utils/filters'

export default {
  name: 'SharedIndex',
  components: {
    TableColumn,
    QrcodePopup,
    CreateItemPopup,
    CreateCampaignPopup,
    CreateChildrenPopup,
    FilterDrawer,
    SynchronizeButton,
    ExportExcelButton
  },
  props: {
    columns: Array,
    actions: Array,
    type: String,
    titles: Array,
    headerActions: Array,
    filterable: {
      type: Boolean,
      default: false
    },
    children: {
      type: Boolean,
      default: false
    },
    parentId: {
      type: String,
      default: ''
    },
    areaId: String
  },
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      loading: true,
      searchText: '',
      tableData: [],
      order: '-created_at',
      selectedRow: null,
      qrVisible: false,
      createVisible: false,
      createCampainVisible: false,
      childrendVisible: false,
      activeTab: this.titles[0].value,
      visibleFilter: false
    }
  },
  computed: {
    ...mapGetters(['language']),
    tableColumns() {
      return this.columns.map((c) =>
        CONSTANTS.ALL_COLUMNS.find((column) => c === column.value)
      )
    },

    tableActions() {
      let actions = this.actions
      if (this.type === 'comment') {
        actions = this.actions.filter((a) => a !== (this.activeTab === 'true' ? 'browser' : 'unbrowser'))
      }
      return actions.map((a) =>
        CONSTANTS.ALL_ACTIONS.find((action) => a === action.key)
      )
    },

    tableHeaderActions() {
      return this.headerActions.map((a) =>
        CONSTANTS.HEADER_ACTIONS.find((action) => a === action.key)
      )
    },

    requestParams() {
      let params = {
        locale: this.language,
        page: this.page,
        per_page: this.limit,
        order: this.order,
        search_text: this.searchText
      }
      if (this.type === 'comment') {
        params = { ...params, approved: this.activeTab }
      } else if (this.type === 'campaign') {
        params = { ...params, campaign_types: this.activeTab }
      } else {
        params = { ...params, place_types: this.type }
        if (!this.children) {
          params = { only_parent: true, ...params }
        } else {
          params = { only_children: true, ...params }
          if (this.parentId) {
            params = { parent_id: this.parentId, ...params }
          }
        }
      }
      if (this.areaId) {
        params = { ...params, area_id: this.areaId }
      }
      return params
    }
  },
  watch: {
    activeTab: 'handleReloadDada',
    language: 'handleReloadDada'
  },
  beforeMount() {
    this.loadDataOfPage()
  },
  methods: {
    getToShowRouter,
    ...mapActions('public', ['updateReloadConstant']),

    loadDataOfPage(params = this.requestParams) {
      this.loading = true
      let request = null
      if (this.type === 'comment') {
        request = getComments(params)
      } else if (this.type === 'campaign') {
        request = getCampaigns(params)
      } else if (this.type === 'tour') {
        request = listTours(params)
      } else if (this.type === 'post') {
        request = listPosts(params)
      } else {
        request = listPlaces(params)
      }
      request.then((response) => {
        this.tableData = []
        this.$nextTick(() => {
          this.tableData = response.result.map((r, index) => ({
            ...r,
            stt: index + 1 + (this.page - 1) * this.limit
          }))
        })
        this.total = response.total
        this.loading = false
      })
        .catch(() => {
          this.loading = false
        })
    },

    handleChangeSize(size) {
      this.limit = size
      this.page = 1
      this.loadDataOfPage()
    },

    handleChangePage(page) {
      this.page = page
      this.loadDataOfPage()
    },

    handleSearchText() {
      this.page = 1
      this.loadDataOfPage()
    },

    handleSortData(column) {
      this.order = `${column.order === 'descending' ? '-' : ''}${column.prop}`
      this.page = 1
      this.loadDataOfPage()
    },

    handleReloadDada() {
      if (this.children) {
        this.$emit('reload')
      }
      this.$refs['shared-filter'].handleResetForm()
      this.$nextTick(() => {
        this.page = 1
        this.searchText = ''
        this.order = '-created_at'
        this.loadDataOfPage()
      })
    },

    handleFilterData(filterParams) {
      if (this.children) {
        this.$emit('reload')
      }
      this.$nextTick(() => {
        this.page = 1
        this.searchText = ''
        this.order = '-created_at'
        this.loadDataOfPage({ ...this.requestParams, ...filterParams })
      })
    },

    handleGetChildrens() {
      this.page = 1
      this.searchText = ''
      this.order = '-created_at'
      this.loadDataOfPage()
    },

    handleHeaderAction(button) {
      switch (button.key) {
        case 'add':
          if (this.type === 'campaign') {
            this.createCampainVisible = true
            return
          }
          this.createVisible = true
          break
        case 'filter':
          this.visibleFilter = true
          break
        case 'reset':
          this.handleReloadDada()
          break
        case 'cook':
          this.$router.push({ name: 'ManageCook' })
          break
        case 'cashier':
          this.$router.push({ name: 'ManageCashier' })
          break
        default:
          break
      }
    },

    handleAction(key, row) {
      this.selectedRow = row
      switch (key) {
        case 'showQR':
          this.qrVisible = true
          break
        case 'add':
          this.childrendVisible = true
          break
        case 'childrens':
          this.$emit('getChildrens', row)
          break
        case 'copy':
          this.$clipboard(`${window.location.origin}/place/${row.id}?locale=${this.language}`)
          this.$notify.success({
            title: 'Thông báo !',
            message: 'Đã copy thành công !'
          })
          break
        case 'edit':
          this.$router.push({ name: `${this.$route.name}Info`, params: { id: row.id }})
          break
        case 'detail':
          this.$router.push({ name: `${this.$route.name}Detail`, params: { id: row.id }})
          break
        case 'browser':
          updateComment({ id: row.id, approved: true }).then(() => {
            this.$notify.success({
              title: 'Thông báo',
              message: 'Phê duyệt thành công.'
            })
            this.loadDataOfPage()
          }).catch((error) => {
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
          break
        case 'unbrowser':
          updateComment({ id: row.id, approved: false }).then(() => {
            this.$notify.success({
              title: 'Thông báo',
              message: 'Bỏ phê duyệt thành công.'
            })
            this.loadDataOfPage()
          }).catch((error) => {
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
          break
        case 'delete':
          this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Đóng',
            type: 'warning'
          }).then(() => {
            let request = null
            if (this.type === 'comment') {
              request = deleteComment({ ids: this.selectedRow.id })
            } else if (this.type === 'tour') {
              request = deleteTour({ ids: this.selectedRow.id })
            } else if (this.type === 'campaign') {
              request = deleteCampaign({ ids: this.selectedRow.id })
            } else if (this.type === 'post') {
              request = deletePost(this.selectedRow.id)
            } else {
              request = deletePlace(this.selectedRow.id)
            }
            request.then(() => {
              this.$notify.success({
                title: 'Thông báo',
                message: 'Xóa thành công'
              })
              if (this.tableData.length === 1) {
                this.page = this.page > 1 ? this.page - 1 : this.page
              }
              this.loadDataOfPage()
              if (['country', 'area'].includes(this.type)) {
                this.handleReloadConstant()
              }
            }).catch((error) => {
              this.$notify.error({
                title: 'Thông báo',
                message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
              })
            })
          })
          break
        default:
          break
      }
    },

    pageTitle(currentTitle) {
      const title = this.titles.find((t) => t.value === currentTitle)
      return `${title.label} ${this.activeTab === currentTitle && !this.loading ? `(${this.total})` : ''}`
    },

    handleReloadConstant() {
      this.updateReloadConstant(dayjs().unix())
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
