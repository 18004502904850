<template>
  <el-dialog
    top="80px"
    width="700px"
    class="create-campaign"
    :title="'Tạo mới campaign'"
    :visible="visible"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-row :gutter="12">
        <el-col :lg="8">
          <el-form-item label="Loại Campaign" prop="type">
            <el-select
              v-model="form.type"
              :disabled="true"
              filterable
              class="w-100"
            >
              <el-option
                v-for="t in campaignTypes"
                :key="t.id"
                :label="t.name"
                :value="t.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="16">
          <el-form-item label="Thời gian áp dựng" prop="dates">
            <el-date-picker
              v-model="form.dates"
              :disabled="callingAPI"
              type="daterange"
              range-separator="Đến"
              start-placeholder="Ngày bắt đầu"
              end-placeholder="Ngày kết thúc"
              format="dd-MM-yyyy"
              value-format="dd/MM/yyyy"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Tên campaign" prop="name">
        <el-input
          v-model="form.name"
          :disabled="callingAPI"
          placeholder="Nhập vào tên để tạo mới"
        ></el-input>
      </el-form-item>
      <el-form-item label="Loại điểm đến" prop="placeType">
        <el-select
          v-model="form.placeType"
          :disabled="callingAPI"
          filterable
          class="w-100"
          @change="handleChangePlaceType"
        >
          <el-option
            v-for="t in placeTypes"
            :key="t.value"
            :label="t.name"
            :value="t.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Danh thắng cha / Tour" prop="place">
        <el-select
          v-model="form.place"
          class="w-100"
          :disabled="callingAPI"
          filterable
          remote
          placeholder="Chọn danh thắng cha / tour"
          :remote-method="searchPlace"
          :loading="searching"
        >
          <el-option
            v-for="p in places"
            :key="p.id"
            :label="p.name"
            :value="p.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="$emit('close')">Hủy</el-button>
      <el-button
        type="primary"
        :loading="callingAPI"
        @click="handleCreateCampaign"
      >Tạo</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { listPlaces } from '@/services/place'
import { listTours } from '@/services/tour'
import { createCampaign } from '@/services/campaign'
import { getPlaceTypes } from '@/utils/filters'

export default {
  name: 'CreateCampaign',
  props: {
    visible: Boolean,
    type: String,
    areaId: String
  },
  data() {
    return {
      callingAPI: false,
      searchText: '',
      searching: false,
      places: [],
      form: {
        type: this.type,
        name: '',
        dates: '',
        placeType: '',
        place: ''
      },
      rules: {
        type: [
          { required: true, message: ' ', trigger: ['blur', 'change'] }
        ],
        name: [
          { required: true, message: 'Tên Campaign không được để trống', trigger: ['blur', 'change'] }
        ],
        dates: [
          { required: true, message: 'Thời gian không được để trống', trigger: ['blur', 'change'] }
        ],
        placeType: [
          { required: true, message: 'Loại điểm đến không được để trống', trigger: ['blur', 'change'] }
        ],
        place: [
          { required: true, message: 'Điểm đến / tour không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    placeTypes() {
      return getPlaceTypes().filter((p) => p.type === 'place')
    },
    campaignTypes() {
      return this.constant.constants.campaign_types
    },
    params() {
      return {
        locale: this.language,
        place_types: this.form.placeType,
        per_page: 10,
        search_text: this.searchText
      }
    },
    campaignInput() {
      return {
        campaign_type: this.form.type,
        content: '',
        end_time: this.form.dates[1],
        locale: this.language,
        name: this.form.name,
        object_id: this.form.place,
        object_type: this.form.placeType === 'tour' ? 'tour' : 'place',
        start_time: this.form.dates[0],
        status: false,
        area_id: this.areaId || null
      }
    }
  },
  methods: {
    getPlaces() {
      this.searching = true
      const request = this.form.placeType === 'tour' ? listTours(this.params) : listPlaces(this.params)
      request.then((res) => {
        this.places = res.result
        this.searching = false
      }).catch(() => {
        this.searching = false
      })
    },
    handleChangePlaceType() {
      this.form.place = ''
      this.searchText = ''
      this.getPlaces()
    },

    searchPlace(text) {
      this.searchText = text
      setTimeout(() => {
        this.getPlaces()
      }, 100)
    },
    handleCreateCampaign() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          createCampaign(this.campaignInput).then(() => {
            this.$emit('close')
            this.$emit('reload')
            this.$notify.success({
              title: 'Thông báo',
              message: 'Tạo mới campaign thành công'
            })
          }).catch((error) => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.create-campaign {
  .el-range-separator {
    width: 6%;
  }
}
</style>
